/*pages*/
.main {
	background-color:rgba(255,255,255,0.85);
	max-height:70vh;
	overflow-y:auto;
	padding:20px 25px;
}
.contact-info-row p {line-height:1;}
.contact-info {
	font-weight:bold;
	@extend secondary-font;
	}
.page-header h1 {
	text-align:center;
	font-size:23px;
	display:none;
}
.contact-page {
	margin-top:25px;
	
	.main {
	padding:20px;
	overflow-y:auto;
	max-height:75vh;
	overflow-x:hidden;
	
		img {margin-bottom:10px;}
	}
}
.error404 .content {width:100%;}
.page-template-template-private input {display:block;}
/*---------------------SCROLLBAR-------------------------------*/

::-webkit-scrollbar {
    width: 9px;
}
 
/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 3px;
    border-radius: 3px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(175,175,175,0.8); 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(0,0,0,0.4); 
}