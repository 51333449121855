body#tinymce {
  margin: 12px !important;
}
/*
MOBILE CSS
*/
@media (max-width: 1400px) {
	.main, .contact-page {max-height:55vh;}
}
/*********DESKTOP**********/
@media (max-width: 1200px) {
	.main, .contact-page {max-height:51vh;}

}
/*********LAPTOP**********/
@media (max-width: 992px) {
	.brand {font-size:25px;}
	.tagline {font-size:17px;}
	.main, .contact-page {max-height:50vh;}
	.gallery-row {height:70px;}

}
/*********TABLET**********/
@media (max-width: 768px) {
	header {padding:15px 0;}
	header .brand, header h1 {font-size:23px;text-align:center;}
	.tagline {font-size:13px;text-align:center;}
	#envirabox-title-float-main {
		font-size:12px;
	}
	.container-fluid {
		padding-left:0;
	}
	footer {
		width:100%;
		}
	.open-menu {
		margin-right:0;
		}
	.main, .contact-page {
		max-height:65vh;
		margin-bottom:30px;
		margin-top:0;
	}
	.open-menu {
	float:left;
	}
	.open-content {
	float:right;
	}
	#envirabox-title-float-wrap td {
    	white-space: pre-wrap !important;
   		max-width: 98%;
		margin-left:5%;
	}
}
/*********PHONE**********/
@media (max-width: 544px) {
	.main, .contact-page {max-height:100%;}
	.brand {font-size:23px;text-align:center;}
	.tagline {font-size:13px;text-align:center;}
	.ata-gallery, .go-right, .go-left {display:none;}
	.open-content {display:none;}
	.mobile-gallery {display:block;}
	.mobile-background {
		background-image: url('/wp-content/uploads/2017/05/Art-Rule-Rule-1_12px-X-600px.jpg');
		background-position:center;
		background-size:cover;
		background-repeat:no-repeat;
		height:12px;
	}
	
}
@media (max-width: 736px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) { 
	.main, .contact-page {max-height:100%;}
	.brand {font-size:23px;text-align:center;}
	.tagline {font-size:13px;text-align:center;}
	.ata-gallery, .go-right, .go-left {display:none;}
	.open-content {display:none;}
	.mobile-gallery {display:block;}
	.mobile-background {
		background-image: url('/wp-content/uploads/2017/05/Art-Rule-Rule-1_12px-X-600px.jpg');
		background-position:center;
		background-size:cover;
		background-repeat:no-repeat;
		height:12px;
	}
}