/*GLOBAL*/
/*------------typography-----------------------------------------*/
@font-face {
    font-family: 'Copperplate';
    src: url('../fonts/copperplate.ttf');
}
secondary-font {
font-family: 'Cinzel', serif;
}
copperplate-font {
font-family: 'Copperplate', sans-serif;
}
primary-font {
font-family: 'Roboto', sans-serif;
}
h1, h2, h3, h4, h5, ul, li {
	@extend secondary-font;
	color:$font-color;
	line-height:1.2;
	letter-spacing:1px;
}
p, body, .gform_wrapper input[type=text], textarea, .gform_wrapper textarea {
	@extend primary-font;
	color:$font-color;
	line-height:1.5;
	font-size:17px;
}
a {
	@extend primary-font;
	color:#666699;
	line-height:1.2;
}
a:hover, a:focus {
	color:#666699;
	text-decoration:underline;
}
h1 {
	font-size:33px;
}
h2 {
	font-size:27px;
}
h3 {
	font-size:21px;
}
h4 {
	font-size:17px;
}
h5 {
	font-size:15px;
}
h6 {@extend primary-font; font-size:13px;}
.content {
	width:100%;
}
.main-content {padding-right:0 !important;}
.wp-caption-text {font-style:italic;padding:5px;}
label {@extend secondary-font;}
.gform_wrapper .top_label .gfield_label {margin:10px 0 2px 0;}
.mobile-gallery {display:none;}
/*-----------------------BTN-----------------------------------------*/
.ata-btn, input[type="submit"] {
  -webkit-border-radius: 5;
  -moz-border-radius: 5;
  border-radius: 5px;
  color: $white;
  @extend secondary-font;
  font-size:14px;
  background: $grey;
  padding: 5px 10px 4px 10px;
  text-decoration: none;
  border:none;
}

.ata-btn:hover, input[type="submit"]:hover {
  background: $black;
  text-decoration: none;
  color:$white;
}
/*-------------------navigation--------------------------------------*/
.navbar-toggler {
	float:right;
	cursor:pointer;
}
.nav-primary {
	.menu-private-gallery {margin-top:10px;}
	a:hover {color:$white !important;}
	> ul > li {padding-bottom:10px;}
	> ul > li > a {@extend h3; color:$white;}
	> ul > li > ul > li >a { font-size:17px !important; @extend secondary-font; color:$white; font-weight:300;letter-spacing:3px;}
	> ul > li > ul >li > ul >li >a { font-size:15px !important; @extend primary-font;color:$white; font-weight:300;letter-spacing:2px;}
	width:275px;
	background-color:rgba(0,0,0,0.85);
	visibility:hidden;
	z-index:999;
	overflow-y:auto;
	> ul  li.menu-item-has-children {
		margin-top:10px;
	}
	.navbar-nav {
	padding:160px 10px 0 20px;
	list-style:none;
	a {
	color:$white;
	font-size:21px;
	letter-spacing:4px;
	}
	> li > .sub-menu {list-style:none;}
	li {margin-bottom:0; color:$white;}
	ul {margin:0; color:$white; list-style:disc;padding-left:28px;}
	}
}
.close-menu {
	color:$grey;
	font-size:15px;
	font-size: 35px;
    position: absolute;
    top: 145px;
    right: 15px;
	cursor:pointer;
}
.social-icons {
padding-left:20px;
}
/*--------------GALLERY-------------------------------------------*/
.wrap {
	overflow:hidden;
}
.gallery-row {
	position:absolute;
	right:0;
	bottom:1px;
	height:85px;
	overflow:hidden;
	transition-timing-function: ease-in-out;
}
.ata-wrap {
	overflow:hidden;
}
.ata-gallery {
	width:9999px;
}
.go-left, .go-right {
	position:absolute;
	height:100%;
	cursor:pointer;
	width:30px;
	z-index:999;
	color:$white;
	font-size:29px;
}
.go-left {
	left:15px;
	background: rgba(133,133,133,1);
	background: -moz-linear-gradient(left, rgba(133,133,133,1) 0%, rgba(255,255,255,0) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(133,133,133,1)), color-stop(100%, rgba(255,255,255,0)));
	background: -webkit-linear-gradient(left, rgba(133,133,133,1) 0%, rgba(255,255,255,0) 100%);
	background: -o-linear-gradient(left, rgba(133,133,133,1) 0%, rgba(255,255,255,0) 100%);
	background: -ms-linear-gradient(left, rgba(133,133,133,1) 0%, rgba(255,255,255,0) 100%);
	background: linear-gradient(to right, rgba(133,133,133,1) 0%, rgba(255,255,255,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#858585', endColorstr='#ffffff', GradientType=1 );
	i {
		top:25%;
		position:absolute;
		left:2px;
	}

}
.go-right {
	background: rgba(133,133,133,0);
	background: -moz-linear-gradient(left, rgba(133,133,133,0) 0%, rgba(133,133,133,1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(133,133,133,0)), color-stop(100%, rgba(133,133,133,1)));
	background: -webkit-linear-gradient(left, rgba(133,133,133,0) 0%, rgba(133,133,133,1) 100%);
	background: -o-linear-gradient(left, rgba(133,133,133,0) 0%, rgba(133,133,133,1) 100%);
	background: -ms-linear-gradient(left, rgba(133,133,133,0) 0%, rgba(133,133,133,1) 100%);
	background: linear-gradient(to right, rgba(133,133,133,0) 0%, rgba(133,133,133,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#858585', endColorstr='#858585', GradientType=1 );
	right:15px;
	i {	
		top:25%;
		position:absolute;
		right:2px;
	}
}
/*---------ENVIRA CUSTOM CSS------------------------------------------------*/
#envirabox-outer {
	background-color:black !important;
}
#envirabox-content {
	border:17px solid black !important;
}
#envirabox-title {
	font-family: 'Roboto', sans-serif !important;
	font-size:16px !important;
}
#envirabox-title-float-main {
	border-radius:0 !important;
	-webkit-border-radius:0 !important;
	color:#ccc !important;
	padding:5px 7px !important;
	font-size: 15px !important;
	line-height:1.1 !important;
}
.justified-gallery > div > div > a > .caption, .justified-gallery > div > div > .caption, .justified-gallery > div > div .caption {
    bottom: 0;
    padding: 2px 4px !important;
    background-color: rgba(0,0,0,0.9) !important;
    left: 0;
    right: 0;
    margin: 0;
    color: $white !important;
    font-size: 13px;
    font-weight: 300;
    font-family: 'Copperplate', sans-serif !important;
	letter-spacing:1px;
    z-index: 2;
}
.justified-gallery > div > div > .caption, .justified-gallery > div > div .caption {visibility:hidden;}
#envirabox-title-float-wrap td {
    border: none;
}

/*---------------------------------------------------------*/
