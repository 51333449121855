/*FOOTER*/
footer {
	background-color:$black;
	padding:25px 0 15px 0;
	color:$grey;
	min-height:68px;
	
	.textwidget {
		float:right;
		padding-right:20px;
		}
	
	p, h2, h3, h4 {
		color:$grey;
		font-size:14px;
		font-family: 'Roboto';
		letter-spacing:1px;
		}
		.footer-content {
		   
		}
}