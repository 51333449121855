/*HEADER*/
header {
	h1 {text-align:center;}
	background-color:$black;
	padding:20px 0;
	color:$white;
	
}
.brand {
	color:$white;
	font-weight:300;
	@extend h1;
	text-decoration:none;
	text-align:center;
	letter-spacing:0.5em;
	@extend copperplate-font;
}
.brand:hover {
	text-decoration:none;
	color:$white;
	-webkit-transform: scale(1.2);
    transform: scale(1.2);
}
.tagline {
	color:$white;
	font-weight:300;
	@extend h2;
	text-align:center;
	letter-spacing:0.3em;
	@extend copperplate-font;
}
.open-menu, .open-content {
	color:$grey;
	cursor:pointer;
}
.open-menu {
	float:right;
	margin-left:10px;
}
.open-content {
	float:left;
}
.nav-primary {
	visibility:hidden;
}
.icon {
	font-size:50px;
}