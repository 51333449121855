// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #27ae60;

$black: black;
$white: #F4F4F4;
$grey: #808080;
$font-color: #2a2a2a;

@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed|Roboto:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Cinzel');
