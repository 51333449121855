/*POSTS*/
article.marketing {
	margin:0 0 50px 0;
	}
.entry-title a {
	@extend h2;
}
.cta p {
     margin-top:40px;
}
.post-type-archive-marketing article {
border-bottom: 1px solid $grey;
padding-bottom:10px;

	.entry-title {text-align:center;}
}